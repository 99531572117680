import _ from 'lodash';
import { request as _request, sseRequest } from '../http';

export const routes = {
  accountStatements: `${process.env.HOST}/api/account_statements`,
  accounts: `${process.env.HOST}/api/accounts`,
  activities: `${process.env.HOST}/api/activities`,
  appointments: `${process.env.HOST}/api/appointments`,
  billingPlanUsers: `${process.env.HOST}/api/billing_plan_users`,
  billingPlans: `${process.env.HOST}/api/billing_plans`,
  bills: `${process.env.HOST}/api/bills`,
  brokerageAccounts: `${process.env.HOST}/api/brokerage_accounts`,
  buys: `${process.env.HOST}/api/buys`,
  buySchedules: `${process.env.HOST}/api/buy_schedules`,
  buyUsers: `${process.env.HOST}/api/buy_users`,
  calendar: `${process.env.HOST}/api/calendar`,
  chats: `${process.env.HOST}/api/chats`,
  codes: `${process.env.HOST}/api/codes`,
  comments: `${process.env.HOST}/api/comments`,
  companies: `${process.env.HOST}/api/companies`,
  deviceContacts: `${process.env.HOST}/api/device_contacts`,
  devices: `${process.env.HOST}/api/devices`,
  dividends: `${process.env.HOST}/api/dividends`,
  documents: `${process.env.HOST}/api/documents`,
  employments: `${process.env.HOST}/api/employments`,
  filings: `${process.env.HOST}/api/filings`,
  funding: `${process.env.HOST}/api/funding`,
  gifts: `${process.env.HOST}/api/gifts`,
  groupBuys: `${process.env.HOST}/api/group_buys`,
  groups: `${process.env.HOST}/api/groups`,
  groupCompanies: `${process.env.HOST}/api/group_companies`,
  groupDividends: `${process.env.HOST}/api/group_dividends`,
  groupSells: `${process.env.HOST}/api/group_sells`,
  holdings: `${process.env.HOST}/api/holdings`,
  invitations: `${process.env.HOST}/api/invitations`,
  liquidations: `${process.env.HOST}/api/liquidations`,
  menuItems: `${process.env.HOST}/api/menu_items`,
  news: `${process.env.HOST}/api/news`,
  notifications: `${process.env.HOST}/api/notifications`,
  orders: `${process.env.HOST}/api/orders`,
  paymentMethods: `${process.env.HOST}/api/payment_methods`,
  phones: `${process.env.HOST}/api/phones`,
  plaid: `${process.env.HOST}/api/plaid`,
  portfolioReviews: `${process.env.HOST}/api/portfolio_reviews`,
  portfolios: `${process.env.HOST}/api/portfolios`,
  positions: `${process.env.HOST}/api/positions`,
  prices: `${process.env.HOST}/api/prices`,
  profiles: `${process.env.HOST}/api/profiles`,
  promos: `${process.env.HOST}/api/promos`,
  proposals: `${process.env.HOST}/api/proposals`,
  reactions: `${process.env.HOST}/api/reactions`,
  rules: `${process.env.HOST}/api/rules`,
  s3: `${process.env.HOST}/api/s3`,
  scheduledBuys: `${process.env.HOST}/api/scheduled_buys`,
  searches: `${process.env.HOST}/api/searches`,
  sells: `${process.env.HOST}/api/sells`,
  settings: `${process.env.HOST}/api/settings`,
  shareRequests: `${process.env.HOST}/api/share_requests`,
  shares: `${process.env.HOST}/api/shares`,
  simulations: `${process.env.HOST}/api/simulations`,
  stocks: `${process.env.HOST}/api/stocks`,
  stripe: `${process.env.HOST}/api/stripe`,
  suggestions: `${process.env.HOST}/api/suggestions`,
  tags: `${process.env.HOST}/api/tags`,
  taxStatements: `${process.env.HOST}/api/tax_statements`,
  templates: `${process.env.HOST}/api/templates`,
  timing: `${process.env.HOST}/api/timing`,
  tokens: `${process.env.HOST}/api/tokens`,
  tradeConfirmations: `${process.env.HOST}/api/trade_confirmations`,
  transfers: `${process.env.HOST}/api/transfers`,
  urlSummaries: `${process.env.HOST}/api/url_summaries`,
  userPromos: `${process.env.HOST}/api/user_promos`,
  users: `${process.env.HOST}/api/users`,
  verifications: `${process.env.HOST}/api/verifications`,
  votes: `${process.env.HOST}/api/votes`,
  waitlist: `${process.env.HOST}/api/waitlist`,
  watchlist: `${process.env.HOST}/api/watchlist`,
};

const parseCompany = (data) => {
  if (!data.description.includes(' Read More ')) {
    let start = _.truncate(data.description, {
      length: 500,
      separator: '. ',
      omission: '.',
    });

    data.description = data.description.replace(start, `${start} Read More `);
  }

  const [shortDescription, longDescription] = data.description.split(' Read More ', 2);

  return {
    ...data,
    shortDescription,
    longDescription,
    filters: {},
  };
};

const request = async (options, event) => {
  //include idToken as query param to bust cache if it changes
  if (options.method === 'GET' && _.has(options, 'headers.Authorization')) {
    if (_.isPlainObject(options.data) && !options.data.idToken) {
      options.data.idToken = options.headers.Authorization;
    } else {
      options.data = { idToken: options.headers.Authorization };
    }
  }

  const { response, data } = await _request(options);
  if (response.ok && process.env.ANALYTICS === '1' && event) { 
    analytics.track(event);
  }

  if (process.env.NODE_ENV === 'development') {
    console.log({
      url: options.url,
      ..._.omit(options.data, 'idToken'),
    });
    console.log(data);
  }

  return { response, data };
}

export const methods = (getAuthHeaders, getAuthQueryParam) => ({
  accountStatements: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.accountStatements,
        method: 'GET',
      });
    
      if (response.ok) {
        return data;
      }
    
      throw new Error(data.error || 'Unknown error'); 
    },
  },
  accounts: {
    getMine: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.accounts}/mine`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getKyc: async ({ userId } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.accounts}/kyc`,
        method: 'GET',
        data: { userId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ uuid, depositFrequency, groupBuyingPower }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.accounts}/${uuid}`,
        method: 'PUT',
        data: { depositFrequency, groupBuyingPower },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  activities: {
    get: async ({ beforeId } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.activities}`, 
        method: 'GET',
        data: { beforeId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  appointments: {
    get: async ({ time } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.appointments,
        method: 'GET',
        data: { time },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  billingPlanUsers: {
    create: async ({ billingPlanUuid, email, userUuid, startDate }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.billingPlanUsers,
        method: 'POST',
        data: { billingPlanUuid, email, userUuid, startDate },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ billingPlanUuid }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.billingPlanUsers}/${billingPlanUuid}/users`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id } = {}) => {
      const url = id ? `${routes.billingPlanUsers}/${id}` : routes.billingPlanUsers;
      const { response, data } = await request({
        ...getAuthHeaders(),
        url,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ id, status }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.billingPlanUsers}/${id}`,
        method: 'PUT',
        data: { status },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  billingPlans: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.billingPlans,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.billingPlans}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    create: async ({ code, name, description, amount, frequency }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.billingPlans,
        method: 'POST',
        data: { code, name, description, amount, frequency },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  bills: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.bills,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  brokerageAccounts: {
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.brokerageAccounts}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getRemote: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.brokerageAccounts}/${id}/remote`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    close: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.brokerageAccounts}/${id}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return;
      }

      throw new Error(data.error || 'Unknown error');
    },
    create: async ({ ssn }) => {
      if (!ssn) {
        throw new Error('SSN is required');
      }

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.brokerageAccounts}`, 
        method: 'POST',
        data: { ssn },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  buys: {
    get: async ({ companyId, groupId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${groupId}/buys`,
        method: 'GET',
        data: { companyId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.buys}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  buySchedules: {
    create: async ({ cadence = 'first_and_fifteenth', limitPrice = 0, notional, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.buySchedules,
        method: 'POST',
        data: { cadence, limitPrice, notional, ticker },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.buySchedules,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');   
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.buySchedules}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ id, isPaused, limitPrice, notional }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.buySchedules}/${id}`,
        method: 'PUT',
        data: { isPaused, limitPrice, notional },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    }
  },
  buyUsers: {
    update: async ({ id, extraNotional }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.buyUsers}/${id}`,
        method: 'PUT',
        data: { extraNotional },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  calendar: {
    isMarketOpen: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.calendar}/is_market_open`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  chats: {
    streamActivities: ({ groupId }, { onError, onMessage, onOpen }) => {
      const sse = sseRequest(
        `${routes.chats}/activities?groupId=${groupId}&${getAuthQueryParam()}`,
        { onError, onMessage, onOpen },
      );

      return {
        sse,
        closeStream: () => {
          sse.close();
        }
      }
    },
    streamMessages: ({ groupId }, { onError, onMessage, onOpen }) => {
      const sse = sseRequest(
        `${routes.chats}/messages?groupId=${groupId}&${getAuthQueryParam()}`,
        { onError, onMessage, onOpen },
      );

      return {
        sse,
        closeStream: () => {
          sse.close();
        }
      }
    },
    getCompanies: async ({ groupId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.chats}/companies`,
        method: 'GET',
        data: { groupId },
      });

      if (response.ok) {
        return data
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMessages: async ({ aroundId, beforeId, companyId, groupId, limit, sinceId }) => {
      const query = {
        groupId,
      };
      if (aroundId) query.aroundId = aroundId;
      if (beforeId) query.beforeId = beforeId;
      if (companyId) query.companyId = companyId;
      if (limit) query.limit = limit;
      if (sinceId) query.sinceId = sinceId;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.chats}/messages`,
        method: 'GET',
        data: query,
      });

      if (response.ok) {
        return data
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMine: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.chats,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    reactToMessage: async ({ messageId, reactionId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.chats}/messages/${messageId}/reactions`,
        method: 'POST',
        data: { reactionId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    sendMessage: async ({ clientId, groupId, text }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.chats}/messages`,
        method: 'POST',
        data: { clientId, groupId, text },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    setIsTyping: async ({ groupId, isTyping }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.chats}/typing`,
        method: 'PUT',
        data: { groupId, isTyping: isTyping === true },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  collections: {
    get: async (params = {}) => {
      const query = {};
      if (params.limit) query.limit = params.limit;
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.tags,
        method: 'GET',
        data: query,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async (id) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.tags}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    companies: async ({ id, limit = 100, offset = 0 }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.tags}/${id}/companies`,
        method: 'GET',
        data: { limit, offset },
      });

      if (response.ok) {
        return data.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  companies: {
    get: async (ticker) => {
      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.companies}/${ticker.toUpperCase()}`,
        method: 'GET',
      });

      if (response.ok) {
        return parseCompany(data);
      }

      throw new Error(data.error || 'Unknown error');
    },
    getDividend: async ({ ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.companies}/${ticker.toUpperCase()}/dividend`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getFilings: async ({ ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${ticker}/filings`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getByGroup: async ({ groupId, limit }) => {
      const params = {
        groupId,
      };
      if (limit) params.limit = limit;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.companies,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getByTags: async (tags, options = {}) => {
      const query = new URLSearchParams();
      if (options.offset) {
        query.set('offset', options.offset);
      }
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}?${query.toString()}`,
        method: 'GET',
        data: {
          tags: tags.map(({ id }) => id),
        },
      });

      if (response.ok) {
        return data.map(parseCompany);
      }

      throw new Error(data.error || 'Unknown error');
    },
    getByTickers: async (tickers) => {
      const { response, data } = await request({
        url: routes.companies,
        method: 'GET',
        data: { ticker: tickers },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getIsSuggestable: async ({ groupId, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${ticker}/is_suggestable`,
        method: 'GET',
        data: { groupId }
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getOwners: async ({ ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${ticker}/owners`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    follow: async (companyId) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${companyId}/follows`,
        method: 'PUT',
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    followed: async (userId) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId || 'me'}/companies`,
        method: 'GET',
      });

      if (response.ok) {
        return data.map(parseCompany);
      }

      throw new Error(data.error || 'Unknown error');
    },
    popular: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/popular`,
        method: 'GET',
      });

      if (response.ok) {
        return data.map(parseCompany);
      }

      throw new Error(data.error || 'Unknown error');
    },
    unfollow: async (companyId) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${companyId}/follows`,
        method: 'DELETE',
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ companyUuid, displayName, iconUrl, isDelisted, isTracked, listingDay, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${companyUuid}`,
        method: 'PUT',
        data: { displayName, iconUrl, isDelisted, isTracked, listingDay, ticker },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    updatePrice: async ({ ticker, timeOfDay, tradingDay, price }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.companies}/${ticker}/prices/${tradingDay}/${timeOfDay}`,
        method: 'PUT',
        data: { price },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  contacts: {
    get: async ({ userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/contact`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ userId, email, phone, addressOne, addressTwo, city, state, postalCode, country }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/contact`,
        method: 'PUT',
        data: {
          email,
          phone,
          addressOne,
          addressTwo,
          city,
          state,
          postalCode,
          country,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  deviceContacts: {
    get: async (params) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.deviceContacts,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  devices: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.devices,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getOne: async ({ token }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.devices}/${token}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  disclosures: {
    get: async ({ userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/disclosure`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({
      isControlPerson,
      isAffiliatedExchangeOrFinra,
      isPoliticallyExposed,
      isImmediateFamilyExposed,
      userId,
    }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/disclosure`,
        method: 'PUT',
        data: {
          isControlPerson,
          isAffiliatedExchangeOrFinra,
          isPoliticallyExposed,
          isImmediateFamilyExposed,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  dividends: {
    get: async ({ limit = 25, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.dividends,
        method: 'GET',
        data: { limit, ticker },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    }
  },
  employments: {
    get: async ({ userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/employment`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({
      userId,
      employmentStatus,
      employerName,
      employerAddress,
      jobTitle,
    }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/employment`,
        method: 'PUT',
        data: {
          employmentStatus,
          employerName,
          employerAddress,
          jobTitle,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  funding: {
    create: async ({ amount, groupId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.funding,
        method: 'POST',
        data: {
          amount,
          groupId,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  gifts: {
    create: async ({ amount, backgroundColor, emoji, fromEmail, fromName, message, templateUuid, textColor, toEmail, toName }) => {
      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: routes.gifts,
        method: 'POST',
        data: {
          amount,
          backgroundColor,
          emoji,
          fromEmail,
          fromName,
          message,
          templateUuid,
          textColor,
          toEmail,
          toName,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ status } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.gifts,
        method: 'GET',
        data: { status },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.gifts}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    update: async ({ id, toEmail, toPhoneNumber }) => {
      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.gifts}/${id}`,
        method: 'PUT',
        data: {
          toEmail,
          toPhoneNumber,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  groupBuys: {
    create: async ({ groupId, notional, paymentMethodId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groupBuys,
        method: 'POST',
        data: {
          groupId,
          notional,
          paymentMethodId,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ groupId } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groupBuys,
        method: 'GET',
        data: {
          groupId,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getOne: async ({ groupBuyUserId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groupBuys}/${groupBuyUserId}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  groupSells: {
    create: async ({ groupId, percent }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groupSells,
        method: 'POST',
        data: {
          groupId,
          percent,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ groupId, limit = 100 } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groupSells,
        method: 'GET',
        data: {
          groupId,
          limit,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ groupSellUserId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groupSells}/${groupSellUserId}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  groups: {
    broadcast: async ({ groupIds, message, path, subtitle, title }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/notifications`,
        method: 'POST',
        data: { groupIds, message, path, subtitle, title },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    get: async ({ ids, include }) => {
      if (!Array.isArray(ids) || !ids.length) {
        throw new Error(`must supply ids array`);
      }

      const params = { id: ids };
      if (include) params.include = include;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groups,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getAll: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groups,
        method: 'GET',
        data: { all: true },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getSuggested: async (params) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/suggested`,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOneByCode: async ({ code }) => {
      let headers;
      try {
        headers = getAuthHeaders();
      } catch {
        //auth is optional for this endpoint
      }

      const { response, data } = await request({
        ...headers,
        url: `${routes.groups}/${code}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    create: async ({ description, groupId, minimum, monthlyContribution, name, templateId, removedTickers }) => {
      const params = { minimum, name };
      if (monthlyContribution) params.monthlyContribution = monthlyContribution;
      if (description) params.description = description;
      if (groupId) params.groupId = groupId;
      if (templateId) params.templateId = templateId;
      if (removedTickers) params.removedTickers = removedTickers;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groups,
        method: 'POST',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    delete: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${id}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMembership: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${id}/membership`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMemberships: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/memberships`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    updateMembership: async ({ id, isPaused, messagesReadAt, monthlyContribution, pauseReason }) => {
      const body = {};
      if (isPaused !== undefined) {
        body.isPaused = Boolean(isPaused);
        if (isPaused === true && pauseReason) {
          body.pauseReason = pauseReason;
        }
      } else if (monthlyContribution >= 0) {
        body.monthlyContribution = monthlyContribution;
      } else if (messagesReadAt !== undefined) {
        body.messagesReadAt = messagesReadAt;
      } else {
        throw new Error('invalid update params');
      }

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${id}/membership`,
        method: 'PUT',
        data: body, 
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMembers: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${id}/members`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getByUser: async ({ userId }) => {
      if (!userId) throw new Error(`userId is required`);

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groups,
        method: 'GET',
        data: { userId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }, 
    getMine: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groups,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    join: async (code, options = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/members`,
        method: 'POST',
        data: { code, ...options },
      } );

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    leave: async ({ groupId, userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${groupId}/membership/${userId}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    search: async ({ limit, query }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/search`,
        method: 'GET',
        data: { limit, query },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ description, groupId, isPaused, name, whoCanJoin }) => {
      const body = {};
      if (description) body.description = description;
      if (isPaused !== undefined) body.isPaused = isPaused;
      if (name) body.name = name;
      if (whoCanJoin) body.whoCanJoin = whoCanJoin;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${groupId}`,
        method: 'PUT',
        data: body,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  groupCompanies: {
    get: async ({ groupId }) => {
      const { response, data } = await request({
        url: routes.groupCompanies,
        method: 'GET',
        data: { groupId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    create: async ({ companyUuid, groupId, type }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groupCompanies,
        method: 'POST',
        data: { companyUuid, groupId, type },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ id, type }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groupCompanies}/${id}`,
        method: 'PUT',
        data: { type },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    delete: async({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groupCompanies}/${id}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  groupDividends: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.groupDividends,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  holdings: {
    get: async ({ groupId, type }) => {
      const params = {
        type,
      };

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${groupId}/holdings`,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getCompany: async ({ groupId, ticker }) => {
      const params = {};
      if (groupId) params.groupId = groupId;
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.holdings}/${ticker}`,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  identities: {
    get: async ({ userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/identity`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({
      userId,
      legalFirstName,
      legalLastName,
      countryOfCitizenship,
      countryOfTaxResidency,
      fundingSource,
      dateOfBirth,
      departureDate,
      isPermanentResident,
      visaType,
      visaExpirationDate,
    }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/identity`, 
        method: 'PUT',
        data: {
          legalFirstName,
          legalLastName,
          countryOfCitizenship,
          countryOfTaxResidency,
          fundingSource,
          dateOfBirth,
          departureDate,
          isPermanentResident,
          visaType,
          visaExpirationDate,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  incomes: {
    get: async ({ userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/income`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({
      userId,
      annualIncomeMin,
      annualIncomeMax,
      liquidNetWorthMin,
      liquidNetWorthMax,
      totalNetWorthMin,
      totalNetWorthMax,
    }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/income`,
        method: 'PUT',
        data: {
          annualIncomeMin,
          annualIncomeMax,
          liquidNetWorthMin,
          liquidNetWorthMax,
          totalNetWorthMin,
          totalNetWorthMax,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  invitations: {
    get: async ({ direction = 'outgoing', groupId }) => {
      const params = {};
      if (direction) params.direction = direction;
      if (groupId) params.groupId = groupId;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.invitations,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.invitations}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    resend: async (id) => {
      const{ response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.invitations}/${id}`,
        method: 'POST',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    send: async ({ groupId, phoneNumber, toUserId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.invitations,
        method: 'POST',
        data: { groupId, phoneNumber, toUserId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    sendWithCode: async ({ code, groupId, phoneNumber, simulationId, templateId, referralCode }) => {
      const { response, data } = await request({
        url: `${routes.invitations}/anonymous`,
        method: 'POST',
        data: {
          code,
          groupId,
          phoneNumber,
          simulationId,
          templateId,
          referralCode,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ id, status }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.invitations}/${id}`,
        method: 'PUT',
        data: { status },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  liquidations: {
    getOne: async ({ userId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/liquidations/${userId}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  menuItems: {
    create: async ({ name, description, duration, emoji, isComingSoon }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.menuItems,
        method: 'POST',
        data: {
          name,
          description,
          duration: parseInt(duration),
          emoji,
          isComingSoon: isComingSoon === true,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.menuItems,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ uuid, name, description, duration, emoji, isComingSoon }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.menuItems}/${uuid}`,
        method: 'PUT',
        data: {
          name,
          description,
          duration,
          emoji,
          isComingSoon,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  news: {
    create: async ({ groupIds, title, summary, messageText, url }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.news,
        method: 'POST',
        data: { groupIds, title, summary, messageText, url },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  notifications: {
    get: async (params = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.notifications,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getInbox: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.notifications}/inbox`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    markSeen: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.notifications}/${id}`,
        method: 'PUT',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    readInbox: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.notifications}/inbox`,
        method: 'PUT',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    send: async ({ groupId, message, path, subtitle, templateId, title }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/notifications`,
        method: 'POST',
        data: { groupId, message, path, subtitle, templateId, title },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  orders: {
    create: async ({ ticker, side, notional, quantity, fromOrderId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.orders,
        method: 'POST',
        data: { ticker, side, notional, quantity, fromOrderId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ beforeId, groupId, limit, ticker, pending, userId } = {}) => {
      const params = {
        groupId,
        pending: pending === true,
      };

      if (beforeId) params.beforeId = beforeId;
      if (limit) params.limit = limit;
      if (ticker) params.ticker = ticker;
      if (userId) params.userId = userId;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.orders,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.orders}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  paymentMethods: {
    create: async ({ accountName, accountNumber, accountType, routingNumber }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.paymentMethods,
        method: 'POST',
        data: {
          accountName,
          accountNumber,
          accountType,
          routingNumber,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    delete: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.paymentMethods}/${id}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.paymentMethods,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.paymentMethods}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  phones: {
    signInRequest: async ({ phoneNumber }) => {
      const { response, data } = await request({
        url: `${routes.phones}/sign-in/request`,
        method: 'POST',
        data: { phoneNumber },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    signInResponse: async ({ code, phoneNumber }) => {
      const { response, data } = await request({
        url: `${routes.phones}/sign-in/response`,
        method: 'POST',
        data: { code, phoneNumber },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    verifyRequest: async ({ phoneNumber }) => {
      const { response, data } = await request({
        url: `${routes.phones}/verify/request`,
        method: 'POST',
        data: { phoneNumber },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    verifyResponse: async({ code, phoneNumber }) => {
      const { response, data } = await request({
        url: `${routes.phones}/verify/response`,
        method: 'POST',
        data: { code, phoneNumber },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  plaid: {
    createLinkToken: async (redirectPath) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.plaid}/link_tokens`,
        method: 'POST',
        data: {
          redirectUri: `${process.env.HOST}${redirectPath}`,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    linkBankAccount: async (publicToken, accountId) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.plaid}/public_tokens`,
        method: 'POST',
        data: { publicToken, accountId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  portfolioReviews: {
    get: async ({ isComplete } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.portfolioReviews,
        method: 'GET',
        data: { isComplete },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.portfolioReviews}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    update: async ({ id, isComplete, isSeen }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.portfolioReviews}/${id}`,
        method: 'PUT',
        data: { isComplete, isSeen },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  portfolios: {
    getMine: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.portfolios,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ duration, groupId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.portfolios,
        method: 'GET',
        data: { duration, groupId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getSummary: async ({ groupId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.portfolios}/summary`,
        method: 'GET',
        data: { groupId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  positions: {
    close: async ({ ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.positions}/${ticker}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ duration = 'all', groupId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.positions,
        method: 'GET',
        data: { duration, groupId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMine: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.positions,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ groupId, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.positions}/${ticker}`,
        method: 'GET',
        data: { groupId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  prices: {
    get: async ({ duration, epsilon, simplify, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: routes.prices,
        method: 'GET',
        data: { duration, epsilon, simplify, ticker },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
 },
 profiles: {
  getMine: async () => {
    const { response, data } = await request({
      ...getAuthHeaders(),
      url: `${routes.profiles}/mine`,
      method: 'GET',
    });

    if (response.ok) {
      return data;
    }

    throw new Error(data.error || 'Unknown error');
  },
  update: async ({ investmentObjective, riskTolerance, timeHorizon }) => {
    const { response, data } = await request({
      ...getAuthHeaders(),
      url: `${routes.profiles}/mine`,
      method: 'PUT',
      data: { investmentObjective, riskTolerance, timeHorizon },
    });

    if (response.ok) {
      return data;
    }

    throw new Error(data.error || 'Unknown error');
  }
 },
  promos: {
    get: async ({ limit, type } = {}) => {
      const params = {};
      if (limit) params.limit = limit;
      if (type) params.type = type;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.promos,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.promos}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    create: async ({ code, expiresAt, name, notional, numRedemptionsAllowed, type }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.promos,
        method: 'POST',
        data: {
          code,
          expiresAt,
          name,
          notional,
          numRedemptionsAllowed,
          type,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  proposals: {
    create: async ({ comment, groupId, ticker, type, weight }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.proposals,
        method: 'POST',
        data: { comment, groupId, ticker, type, weight },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ groupId, isLive } = {}) => {
      const params = {};
      if (groupId) params.groupId = groupId;
      if (isLive === true || isLive === false) params.isLive = isLive;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.proposals,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getVotes: async ({ proposalId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.proposals}/${proposalId}/votes`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    vote: async ({ proposalId, vote }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.proposals}/${proposalId}/votes`,
        method: 'POST',
        data: { vote },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  reactions: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.reactions,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  rules: {
    create: async ({ destination, destinationGroupId, groupId, percent, type }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.rules,
        method: 'POST',
        data: {
          destination,
          destinationGroupId,
          groupId,
          percent,
          type,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ groupId } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.rules,
        method: 'GET',
        data: { groupId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async ({ id, destination, destinationGroupId, percent, status }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.rules}/${id}`,
        method: 'PUT',
        data: {
          destination,
          destinationGroupId,
          percent,
          status,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    }
  },
  scheduledBuys: {
    create: async ({ notional, paymentMethodId, ticker }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.scheduledBuys,
        method: 'POST',
        data: { notional, paymentMethodId, ticker: ticker.toUpperCase() },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.scheduledBuys}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  search: {
    companies: async ({ query, limit }) => {
      if (!query) {
        return null;
      }

      const requestData = { query };
      if (limit) {
        requestData.limit = limit;
      }

      const { response, data } = await request({
        url: `${routes.companies}/search`,
        method: 'GET',
        data: requestData,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    popular: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.searches}/popular`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    recent: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.searches}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    create: async ({ companyUuid, query, tagUuid }) => {
      if (!companyUuid && !tagUuid) {
        return null;
      }
      
      const params = {
        companyUuid: null,
        tagUuid: null,
        query,
      };

      if (companyUuid) params.companyUuid = companyUuid;
      if (tagUuid) params.tagUuid = tagUuid;

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.searches,
        method: 'POST',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    }
  },
  sells: {
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.sells}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  settings: {
    get: async () => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: routes.settings,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    set: async (settings) => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: routes.settings,
        method: 'PUT',
        data: settings,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  shareRequests: {
    create: async ({ toUserId }) => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: routes.shareRequests,
        method: 'POST',
        data: { toUserId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    get: async () => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: routes.shareRequests,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getOne: async ({ toUserId }) => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: `${routes.shareRequests}/${toUserId}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    update: async ({ id, isSeen, status }) => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: `${routes.shareRequests}/${id}`,
        method: 'PUT',
        data: { isSeen, status },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    }
  },
  shares: {
    getOne: async ({ fromUserId }) => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: `${routes.shares}/${fromUserId}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  simulations: {
    get: async ({ templateId, tickers, groupId, reinvestDividends = false, m, y }) => {
      const params = { weeklyInvestment: 2000, reinvestDividends: reinvestDividends === true };
      if (m && y) {
        params.m = m;
        params.y = y;
      }

      if (tickers) {
        params.ticker = tickers.sort((a, b) => a < b ? -1 : 1);
      } else if (templateId) {
        params.templateId = templateId;
      } else if (groupId) {
        params.groupId = groupId;
      } else {
        throw new Error(`must provide groupId, templateId, or tickers`);
      }

      const { response, data } = await request({
        url: routes.simulations,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id, code }) => {
      if (!id && !code) throw new Error(`must provide id or code`);

      const { response, data }  = await request({
        url: `${routes.simulations}/${id || code}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  stocks: {
    get: async (ticker) => {
      if (Array.isArray(ticker)) {
        const { response, data }  = await request({
          ...getAuthHeaders({ strict: false }),
          url: routes.stocks,
          method: 'GET',
          data: { ticker },
        });
  
        if (response.ok) {
          return data;
        }
  
        throw new Error(data.error || 'Unknown error');
      } else {
        const { response, data }  = await request({
          ...getAuthHeaders({ strict: false }),
          url: `${routes.stocks}/${ticker}`,
          method: 'GET',
        });
  
        if (response.ok) {
          return data;
        }
  
        throw new Error(data.error || 'Unknown error');
      }
    },
    chart: async (ticker, duration) => {
      const { response, data }  = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.stocks}/${ticker}/chart/${duration}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    dates: async (ticker, dates) => {
      const { response, data }  = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.stocks}/${ticker}/chart/date`,
        method: 'GET',
        data: { date: dates },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    earnings: async (ticker) => {
      const { response, data }  = await request({
        ...getAuthHeaders({ strict: false }),
        url: `${routes.stocks}/${ticker}/earnings`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  stripe: {
    createPaymentMethod: async (stripeToken) => {
      const { response, data }  = await request({
        ...getAuthHeaders(),
        url: `${routes.stripe}/tokens`,
        method: 'POST',
        data: {
          stripeToken,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  suggestions: {
    create: async ({ companyId, groupId, suggested, isSell, comment = '' }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${groupId}/suggestions`,
        method: 'POST',
        data: {
          comment,
          companyId,
          groupId,
          isSell: isSell === true,
          suggested,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ after, before, companyId, groupId, userId, limit }) => {
      const params = {};
      if (after) params.after = after;
      if (before) params.before = before;
      if (limit) params.limit = limit;
      if (userId) params.userId = userId;
      if (companyId) params.companyId = companyId;
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.groups}/${groupId}/suggestions`,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.suggestions}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getReactions: async ({ suggestionId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.suggestions}/${suggestionId}/reactions`,
        method: 'GET',
      });

      if (response.ok) {
        return data
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    getUpcoming: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.suggestions,
        method: 'GET',
        data: { upcoming: true },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    react: async ({ reactionId, suggestionId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.suggestions}/${suggestionId}/reactions`,
        method: 'POST',
        data: { reactionId },
      });

      if (response.ok) {
        return data
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  tags: {
    addCompany: async({ companyId, tagId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.tags}/${tagId}/companies/${companyId}`,
        method: 'PUT',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');   
    },
    create: async({ name, emoji }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.tags,
        method: 'POST',
        data: { name, emoji },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    delete: async({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.tags}/${id}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    get: async() => {
      const { response, data } = await request({
        url: routes.tags,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    getOne: async({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.tags}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    removeCompany: async({ companyId, tagId }) => {
      const { response } = await request({
        ...getAuthHeaders(),
        url: `${routes.tags}/${tagId}/companies/${companyId}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return;
      }

      throw new Error(data.error || 'Unknown error');  
    },
  },
  taxStatements: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.taxStatements,
        method: 'GET',
      });
    
      if (response.ok) {
        return data;
      }
    
      throw new Error(data.error || 'Unknown error'); 
    },
  },
  templates: {
    addCompany: async({ comment, companyId, notes, templateId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.templates}/${templateId}/companies`,
        method: 'PUT',
        data: { comment, companyId, notes },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');   
    },
    create: async({ name, description, isHidden, isPrivate }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.templates,
        method: 'POST',
        data: {
          name,
          description,
          isHidden: isHidden === true,
          isPrivate: isPrivate === true,
        },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    delete: async({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.templates}/${id}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');   
    },
    get: async() => {
      const { response, data } = await request({
        url: routes.templates,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    getOne: async({ id, code }) => {
      const { response, data } = await request({
        url: `${routes.templates}/${id || code}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    removeCompany: async({ companyId, templateId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.templates}/${templateId}/companies/${companyId}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return;
      }

      throw new Error(data.error || 'Unknown error');  
    },
    update: async({ id, name, description, benchmarkTicker, isHidden }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.templates}/${id}`,
        method: 'PUT',
        data: { benchmarkTicker, description, name, isHidden },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    updateCompany: async({ companyId, notes, templateId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.templates}/${templateId}/companies`,
        method: 'PUT',
        data: { companyId, notes },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');   
    },
  },
  timing: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.timing,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  tradeConfirmations: {
    get: async (params) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.tradeConfirmations,
        method: 'GET',
        data: params
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  transfers: {
    create: async ({ amount, direction }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.transfers,
        method: 'POST',
        data: { amount, direction },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    get: async ({ groupId, pending } = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.transfers,
        method: 'GET',
        data: { groupId, pending },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  urlSummaries: {
    get: async (urls) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.urlSummaries,
        method: 'GET',
        data: { urls },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
  },
  userPromos: {
    get: async ({ userId, promoId, status, type }) => {
      const params = {};
      if (userId) params.userId = userId;
      if (promoId) params.promoId = promoId;
      if (status) params.status = status;
      if (type) params.type = type;

      if (Object.keys(params).length === 0) throw new Error('No params provided');

      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.userPromos,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    create: async ({ userId, promoId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.userPromos,
        method: 'POST',
        data: { userId, promoId },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    }
  },
  users: {
    follow: async (id) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${id}/follows`,
        method: 'PUT',
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    get: async ({ ids, referralCode, offset } = {}) => {
      const params = {};
      if (referralCode) {
        params.rc = referralCode;
      } else if (ids) {
        params.id = ids;
      } else if (offset) {
        params.offset = offset;
      }

      const { response, data } = await request({
        ...getAuthHeaders({ strict: false }),
        url: routes.users,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ id }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${id}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getAccount: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/account`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getMe: async (options = {}) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/me`,
        method: 'GET',
        data: options.noCache ? { cacheBust: new Date().getTime() } : {},
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getToken: async ({ idToken }) => {
      const { response, data } = await request({
        ...getAuthHeaders({ idTokenOverride: idToken }),
        url: routes.tokens,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    search: async ({ groupId, limit, query }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/search`,
        method: 'GET',
        data: { groupId, limit, q: query },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    },
    uploadDocument: async ({ file, type, subType = '' }) => {
      const upload = await request({
        ...getAuthHeaders(),
        url: `${routes.documents}/urls`,
        method: 'POST',
        data: {
          contentType: file.type
        },
      });

      if (!upload.response.ok) {
        throw new Error(upload.data.error || 'Unable to retrieve file URL');
      }

      const { data: { url } } = upload;

      const response = await _request({
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'private',
        },
        url,
        method: 'PUT',
        data: file,
        json: false,
      });

      if (response.ok) {
        const documentUrl = new URL(url);
        documentUrl.search = '';
        const document = await request({
          ...getAuthHeaders(),
          url: routes.documents,
          method: 'POST',
          data: {
            bucket: upload.data.bucket,
            contentType: upload.data.contentType,
            name: upload.data.name,
            type,
            subType,
            url: documentUrl,
          },
        });

        if (document.response.ok) {
          return document.data;
        }
        
        throw new Error(updateUser.data.error || 'Unable to upload document');
      }

      throw new Error(data.error || 'Unable to upload file');
    },
    uploadProfilePicture: async ({ file, userId }) => {
      const upload = await request({
        ...getAuthHeaders(),
        url: `${routes.s3}/upload-url`,
        method: 'GET',
        data: { contentType: file.type },
      });

      if (!upload.response.ok) {
        throw new Error(upload.data.error || 'Unable to retrieve file URL');
      }

      const { data: { url } } = upload;

      const response = await _request({
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read',
        },
        url,
        method: 'PUT',
        data: file,
        json: false,
      });

      if (response.ok) {
        const profilePictureUrl = new URL(url);
        profilePictureUrl.search = '';
        const updateUser = await request({
          ...getAuthHeaders(),
          url: `${routes.users}/${userId}`,
          method: 'PUT',
          data: {
            profilePictureUrl,
          },
        });

        if (updateUser.response.ok) {
          return updateUser.data;
        }
        
        throw new Error(updateUser.data.error || 'Unable to update profile picture');
      }

      throw new Error(data.error || 'Unable to upload file');
    },
    changePassword: async (userId, currentPassword, newPassword) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}/password`,
        method: 'PUT',
        data: { currentPassword, newPassword },
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    forgotPassword: async (email) => {
      const { response, data } = await request({
        url: `${routes.users}/forgot-password`,
        method: 'POST',
        data: {
          email,
        },
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    resendVerificationEmail: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/verify-email`,
        method: 'POST',
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    resetPassword: async (resetToken, password) => {
      const { response, data } = await request({
        ...getAuthHeaders({ idTokenOverride: resetToken }),
        url: `${routes.users}/reset-password`,
        method: 'POST',
        data: {
          password,
        },
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    signIn: async ({ email, password }) => {
      const { response, data } = await request({
        url: routes.tokens,
        method: 'POST',
        data: { email, password },
      });

      if (response.ok) {
        if (process.env.ANALYTICS === '1' ) {
          analytics.identify(data.uuid, {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
          });
        }

        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    signUp: async (fields) => {
      const {
        billingPlanCode,
        bio,
        email,
        firstName,
        investmentObjective,
        invitationsRemaining = 0,
        lastName,
        password,
        promoCode,
        referrerCode,
        username,
      } = fields;
      const { response, data } = await request({
        // headers: { 
        //   'X-Phone-Number-Token': phoneNumberToken,
        // },
        url: routes.users,
        method: 'POST',
        data: {
          billingPlanCode,
          bio,
          email,
          firstName,
          investmentObjective,
          invitationsRemaining,
          lastName,
          password,
          promoCode,
          referrerCode,
          username,
        },
      });

      if (response.ok) {
        if (process.env.ANALYTICS === '1') {
          analytics.identify(data.uuid, {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
          });
        }

        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    unfollow: async (id) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${id}/follows`,
        method: 'DELETE',
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
    update: async (userId, updates) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.users}/${userId}`,
        method: 'PUT',
        data: updates,
      });
      
      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  verifications: {
    get: async () => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.verifications,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getOne: async ({ value }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.verifications}/${value}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error'); 
    }
  },
  waitlist: {
    join: async ({ email }) => {
      const { response, data } = await request({
        url: routes.waitlist,
        method: 'POST',
        data: { email },
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
  watchlist: {
    get: async (params) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: routes.watchlist,
        method: 'GET',
        data: params,
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    getCompany: async ({ companyId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.watchlist}/${companyId}`,
        method: 'GET',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    addCompany: async ({ companyUuid }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.watchlist}/${companyUuid}`,
        method: 'PUT',
      });

      if (response.ok) {
        return data;
      }

      throw new Error(data.error || 'Unknown error');
    },
    removeCompany: async ({ companyId }) => {
      const { response, data } = await request({
        ...getAuthHeaders(),
        url: `${routes.watchlist}/${companyId}`,
        method: 'DELETE',
      });

      if (response.ok) {
        return true;
      }

      throw new Error(data.error || 'Unknown error');
    },
  },
});